<template>
  <!-- 九州产品介绍 头图 -->
  <div>
    <div class="join-top" v-if="screenWidth >= 1400" style="margin-top: 100px">
      <img :src="pcBg" alt="" style="width: 100%" />
    </div>
    <div class="join-top" v-if="screenWidth < 1400" style="margin-top: 100px">
      <img :src="mobileBg" alt="" style="width: 100%" />
    </div>
  </div>
</template>
<script>
let _this;

export default {
  props: {
    pcBg: "",
    mobileBg: "",
  },
  components: {},

  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽

      scroll: "",
      header_fixed: false,
    };
  },

  mounted() {
    // console.log("mounted");
    // 监听页面滚动事件
    window.addEventListener("scroll", this.menu);

    // 监听窗口宽度变化
    window.addEventListener("resize", function () {
      window.screenWidth = document.body.clientWidth;
      _this.screenWidth = window.screenWidth;
    });
  },
  watch: {
    scroll() {
      if (_this.scroll > 0) {
        console.log(`${_this.pcBg}`);
        _this.header_fixed = true;
      } else {
        _this.header_fixed = false;
      }
    },
  },
  created() {
    // console.log(_this)
    _this = this;
  },
  methods: {
    menu() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(this.scroll)
    },
  },
};
</script>
<style>
@media screen and (min-width: 1px) and (max-width: 768px) {
  .software-max .el-carousel__container {
    width: 100% !important;
    /* height: 420px!important; */

    height: calc(100vw / 0.98) !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .software-max .el-carousel__container {
    width: 90% !important;
    /* height: 420px!important; */

    height: calc(100vw / 1.2) !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .software-max .el-carousel__container {
    width: 90% !important;
    /* height: 420px!important; */

    height: calc(100vw / 1.2) !important;
  }
}

@media screen and (min-width: 1440px) {
  .software-max .el-carousel__container {
    width: 100% !important;
    margin: 0 auto;
    /* height: calc(100vw / 2) !important; */
    height: calc(116vw / 2) !important;
  }
}
</style>
<style lang="scss" scoped>
.software-max {
  background-color: #f0f4f7;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .software-max {
    width: 90%;
    margin: 20px auto 20px;
    background-color: #f0f4f7;
    padding-top: 20px;

    .title {
      text-align: center;

      h3 {
        font-family: MicrosoftYaHei;
        color: #121419;
        font-weight: 400;
        font-size: 6vw;
      }

      p {
        font-family: Microsoft YaHei;
        font-weight: 300;
        font-size: 3vw;
        color: #606060;
        line-height: 55px;
        margin-top: 10px;
      }
    }

    .main {
      margin-top: 10px;

      .img-lunbo {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .software-max {
    width: 90%;
    margin: 20px auto 20px;
    background-color: #f0f4f7;
    padding-top: 20px;

    .title {
      text-align: center;

      h3 {
        font-family: MicrosoftYaHei;
        color: #121419;
        font-weight: 400;
        font-size: 5vw;
      }

      p {
        font-family: Microsoft YaHei;
        font-weight: 300;
        font-size: 3vw;
        color: #606060;
        line-height: 55px;
        margin-top: 10px;
      }
    }

    .main {
      margin-top: 10px;
      .mobile {
        padding-left: 70px;
      }

      .img-lunbo {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .software-max {
    width: 90%;
    margin: 20px auto 20px;
    background-color: #f0f4f7;
    padding-top: 20px;

    .title {
      text-align: center;

      h3 {
        font-family: MicrosoftYaHei;
        color: #121419;
        font-weight: 400;
        font-size: 5vw;
      }

      p {
        font-family: Microsoft YaHei;
        font-weight: 300;
        font-size: 3vw;
        color: #606060;
        line-height: 55px;
        margin-top: 10px;
      }
    }

    .main {
      margin-top: 10px;
      .mobile {
        padding-left: 70px;
      }

      .img-lunbo {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .software-max {
    max-width: 1400px;
    height: 1080px;
    margin: 0 auto 95px;

    padding-top: 66px;

    .title {
      text-align: center;

      h3 {
        font-family: MicrosoftYaHei;
        color: #121419;
        font-weight: 400;
        font-size: 46px;
      }

      p {
        font-family: Microsoft YaHei;
        font-weight: 300;
        font-size: 24px;
        color: #606060;
        line-height: 55px;
        margin-top: 26px;
      }
    }

    .main {
      margin-top: 62px;
    }
  }
}
</style>
