<template>
  <div class="about">
    <!--   头部导航     -->
    <headerC :header_fixed="header_fixed" :nav_active="nav_active"></headerC>
    <!-- 产品头图 -->
    <HeadImg :pcBg="pcBg" :mobileBg="mobileBg"></HeadImg>

    <!--  企业简介  -->
    <div
      class="enterprise"
      id="enterprise"
      :class="{ mobile: screenWidth <= 1400 }"
    >
      <ul class="top-link" v-if="screenWidth > 1400">
        <li class="active" @click="intoView('enterprise')">企业简介</li>
        <li @click="intoView('course')">发展历程</li>
        <li @click="intoView('team')">团队介绍</li>
        <li @click="intoView('cooperation')">合作伙伴</li>
      </ul>
      <div class="el-tab-pane">
        <div class="left">
          <h4>Company</h4>
          <h4>Profile</h4>
          <span></span>
          <h3>企业简介</h3>
          <img src="../assets/about-logo.png" alt="" />
        </div>
        <div class="right">
          <h3>吉林省盛世九州科技有限公司</h3>
          <p>
            吉林省盛世九州科技有限公司，成立于2019年，是一家专业从事各种汽车代驾服务的公司，公司拥有自主知识产权的代驾管理系统，实现了电脑、手机双平台管理，代驾员手机操作简便，定位准确，实现24小时快速专业的代驾服务。
          </p>
          <p>
            九州代驾凝聚九州人长期的经营管理、成长发展之道。致力于以最优质的的服务以及最优质的价格来打入市场，用独有的“计费模式”占领市场。目前司机注册人数已超过5000+,业务覆盖已达到9个省份30个地区，投资支持1000万+。在硬件，服务体系，口碑，品牌上具有一定的优势。
          </p>
          <img src="../assets/about-tab-1.png" alt="" />
        </div>
      </div>
    </div>

    <!--  软件介绍  -->
    <div class="introduce-max" :class="{ mobile: screenWidth <= 1400 }">
      <div class="introduce">
        <div class="left">
          <h4>Software</h4>
          <h4>Introduction</h4>
          <span></span>
          <h3>软件介绍</h3>
          <p style="margin-bottom: 40px">
            吉林省盛世九州科技有限公司是一家专业从事各种汽车代驾服务的公司，九州代驾为我公司专门打造的品牌，
            公司引进先进的代驾管理系统，
            24小时提供快速专业的代驾服务。我们秉承客户至上的服务宗旨，
            开始了代驾行业的新模式，平均6分钟接驾,
            较大限度的降低客户等待时间,且等待20分钟内不收费；
            推出了代驾行业的新模式--时间模式，节约客户使用成本；为您和您的爱车保驾护航。
            目前代驾服务已在石家庄、济南覆盖。我们的专业服务已赢得顾客的广泛认可，短短几个月来，用户已达上万，
            让客户满意是我们不断的追求，做全国性专业代驾公司是我们努力的目标。选择盛世九州，为您保驾护航！
          </p>
          <p>
            代驾是一种托付，更是一种信任，盛世九州代驾致力于为每一位顾客提供安全的代驾服务，
            让有车的用户都能放心应酬，舒心到家，让代驾成为一种生活方式，让更多的用户体会科技升级带来的便捷生活。
          </p>
        </div>
        <div class="right">
          <img src="../assets/about-introduce-right.png" alt="" />
        </div>
      </div>
    </div>

    <!--  发展历程  -->
    <div class="course" id="course" :class="{ mobile: screenWidth <= 1400 }">
      <div class="title" v-if="screenWidth <= 1400">
        <h4>Development</h4>
        <h4>History</h4>
        <span></span>
        <h3>发展历程</h3>
      </div>
      <el-tabs
        class="left"
        v-model="course_tab"
        tab-position="bottom"
        style="height: 200px"
      >
        <el-tab-pane label="2021" name="2021"> 2021 </el-tab-pane>
        <el-tab-pane label="2020" name="2020">
          <div class="main">
            <div>
              <div>
                <p>2020年，九州科技高速发展</p>
                <p>吉林、石家庄、太原、济南四地日订单达到2w+</p>
              </div>
              <h3 v-if="screenWidth > 1400">2020</h3>
            </div>
            <img src="../assets/about-history-left.png" alt="" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="2019" name="2019"> 2019 </el-tab-pane>
      </el-tabs>
      <div class="title" v-if="screenWidth > 1400">
        <h4>Development</h4>
        <h4>History</h4>
        <span></span>
        <h3>发展历程</h3>
      </div>
    </div>

    <!--  团队介绍  -->
    <div class="team" id="team" :class="{ mobile: screenWidth <= 1400 }">
      <div class="team-top">
        <div>
          <h4>Team</h4>
          <h4>Introduction</h4>
        </div>
        <div>
          <span></span>
          <h3>团队介绍</h3>
        </div>
      </div>

      <div class="team-main">
        <div>
          <span></span>
          <h5>15+</h5>
          <p>各分公司负责人</p>
          <p>拥有多年管理经验</p>
          <span></span>
        </div>
        <div>
          <span></span>
          <h5>20+</h5>
          <p>互联网技术开发团队</p>
          <p>高端的技术能力</p>
          <span></span>
        </div>
        <div>
          <span></span>
          <h5>5000+</h5>
          <p>超过5年驾龄的司机</p>
          <p>提供专业代驾服务</p>
          <span></span>
        </div>
        <div>
          <span></span>
          <h5>80+</h5>
          <p>客户服务团队</p>
          <p>为用户解决各类问题</p>
          <span></span>
        </div>
        <div>
          <span></span>
          <h5>60+</h5>
          <p>营销实战资深人员</p>
          <p>自营+加盟合作</p>
          <span></span>
        </div>
      </div>
    </div>

    <!--  合作媒介  -->
    <div
      class="cooperation"
      id="cooperation"
      :class="{ mobile: screenWidth <= 1400 }"
    >
      <div class="cooperation-top">
        <div>
          <h4>Cooperative</h4>
          <h4>Media</h4>
        </div>
        <div>
          <span></span>
          <h3>合作媒介</h3>
        </div>
      </div>
      <div class="cooperation-main">
        <div v-for="item in media_img">
          <img :src="item.url" alt="" />
        </div>
        <div></div>
      </div>
    </div>

    <!--  底部  -->
    <footerC></footerC>
  </div>
</template>
<script>
let _this;
import HeadImg from "@/views/headImg.vue";
import headerC from "@/components/headerC.vue";
import footerC from "@/components/footerC.vue";

export default {
  name: "about",
  components: {
    HeadImg,
    headerC,
    footerC,
  },
  created() {
    _this = this;
  },
  data() {
    return {
      pcBg: require("../assets/about-top.png"),
      mobileBg: require("../assets/about-top-mobile.png"),
      header_fixed: false,
      nav_active: "about",
      activeName: "first",
      course_tab: "2020",
      screenWidth: document.body.clientWidth, // 屏幕宽
      scroll: "",
      media_img: [
        {
          url: require("../assets/media-1.png"),
        },
        {
          url: require("../assets/media-2.png"),
        },
        {
          url: require("../assets/media-3.png"),
        },
        {
          url: require("../assets/media-4.png"),
        },
        {
          url: require("../assets/media-5.png"),
        },
        {
          url: require("../assets/media-6.png"),
        },
        {
          url: require("../assets/media-7.png"),
        },
        {
          url: require("../assets/media-8.png"),
        },
        {
          url: require("../assets/media-9.png"),
        },
        {
          url: require("../assets/media-10.png"),
        },
        {
          url: require("../assets/media-11.png"),
        },
      ],
    };
  },
  watch: {
    scroll() {
      if (_this.scroll > 0) {
        _this.header_fixed = true;
      } else {
        _this.header_fixed = false;
      }
    },
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.menu);

    // 监听窗口宽度变化
    window.addEventListener("resize", function () {
      window.screenWidth = document.body.clientWidth;
      _this.screenWidth = window.screenWidth;
    });
  },
  methods: {
    // 锚记链接方法
    intoView(val) {
      document.getElementById(val).scrollIntoView();
    },
    menu() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(this.scroll)
    },
    handleClick(tab, event) {},
  },
};
</script>
<style lang="scss" scoped>
.about-top {
  width: 100%;
  // height: 450px;
  background-image: url("../assets/about-top.png");
  background-size: 100%;
  padding-top: 200px;
  padding-left: 5%;
  box-sizing: border-box;

  div {
    h2 {
      font-weight: bold;
      font-style: italic;
      font-size: 52px;
      color: #ffffff;

      span {
        font-size: 36px;
        color: #73acff;
      }
    }

    p {
      font-size: 20px;
      color: #c8deff;
      font-weight: 400;
      margin-top: 10px;
    }
  }
}
.about-top.mobile {
  background-image: url("../assets/about-top-mobile.png");
  height: calc(100vw * 0.75);
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 100px 0 0;
  box-sizing: border-box;
  div {
    h2 {
      font-size: 26px;
      text-align: center;
      span {
        display: block;
        font-size: 24px;
      }
    }
    p {
      display: none;
    }
  }
}

.enterprise {
  max-width: 1400px;
  margin: 0 auto;
  .top-link {
    display: flex;
    border-bottom: 1px solid #e3e5e8;
    li {
      height: 77px;
      line-height: 77px;
      margin: 0 20px;
      cursor: pointer;
    }
    .active {
      border-bottom: 3px solid #0b6fff;
    }
  }

  .el-tab-pane {
    display: flex;
    padding-top: 50px;
    justify-content: space-between;

    .left {
      width: 20%;

      h4 {
        font-size: 18px;
        color: #888888;
        font-weight: 400;
        height: 26px;
        line-height: 26px;
      }

      & > span {
        display: block;
        margin: 20px 0;
        width: 24px;
        height: 3px;
        background: #0b6fff;
      }

      h3 {
        font-size: 30px;
        color: #333333;
        font-weight: 400;
        margin-bottom: 350px;
      }

      & > img {
        width: 100%;
      }
    }

    .right {
      width: 75%;

      h3 {
        font-size: 30px;
        font-weight: 400;
        color: #0b6fff;
        margin-bottom: 40px;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        color: #555555;
        margin-bottom: 40px;
      }

      & > img {
        width: 100%;
      }
    }
  }
}
.enterprise.mobile {
  padding: 0 30px;
  box-sizing: border-box;
  .el-tab-pane {
    display: block;
    .left {
      width: 100%;
      h4 {
        font-size: 14px;
        height: 20px;
        line-height: 20px;
      }
      span {
      }
      h3 {
        font-size: 20px;
        margin-bottom: 50px;
      }
      img {
        display: none;
      }
    }
    .right {
      width: 100%;
      h3 {
        font-size: 20px;
        margin-bottom: 20px;
      }
      p {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }
}

.introduce-max {
  width: 100%;
  background-image: url("../assets/about-introduce-bg.png");
  background-size: 100% 100%;
  margin-top: 100px;
  position: relative;
  .introduce {
    max-width: 1400px;
    margin: 0 auto;
    padding: 100px 0 150px;
    box-sizing: border-box;
    .left {
      width: 40%;
      h4 {
        font-size: 18px;
        color: #ffffff;
        font-weight: 400;
        height: 26px;
        line-height: 26px;
      }
      & > span {
        display: block;
        margin: 20px 0;
        width: 24px;
        height: 3px;
        background: rgba(255, 255, 255, 0.5);
      }
      h3 {
        font-size: 30px;
        color: #ffffff;
        font-weight: 400;
        margin-bottom: 40px;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
      }
    }
    .right {
      position: absolute;
      right: 0;
      top: 30px;
      width: 60%;
      text-align: right;
      & > img {
        width: 100%;
        max-width: 1080px;
      }
    }
  }
}
.introduce-max.mobile {
  padding: 0 30px;
  box-sizing: border-box;
  .introduce {
    display: block;
    padding: 100px 0 200px;
    .left {
      width: 100%;
      h4 {
        font-size: 14px;
        height: 20px;
        line-height: 20px;
      }
      span {
      }
      h3 {
        font-size: 20px;
        margin-bottom: 50px;
      }
      p {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
    .right {
      width: 100%;
      bottom: -60px;
      top: unset;
    }
  }
}

.course {
  max-width: 1400px;
  margin: 200px auto 0;
  position: relative;
  .left {
    width: 80%;
    height: auto !important;

    .el-tab-pane {
      height: 600px;
      .main {
        height: 100%;
        padding: 0 150px 0 70px;
        justify-content: space-between;
        align-items: center;
        display: flex;
        position: relative;
        h3 {
          font-size: 140px;
          font-weight: bold;
          color: #0b6fff;
          position: absolute;
          left: 3%;
          bottom: -80px;
        }
        p {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
          line-height: 42px;
        }
      }
    }
    .el-tab-pane:nth-of-type(1) {
      background-image: url("../assets/course-bg-1.png");
    }
    .el-tab-pane:nth-of-type(2) {
      background-image: url("../assets/course-bg-1.png");
    }
  }
  .title {
    position: absolute;
    right: 0;
    top: 120px;
    h4 {
      font-size: 18px;
      color: #888888;
      font-weight: 400;
      height: 26px;
      line-height: 26px;
    }

    & > span {
      display: block;
      margin: 20px 0;
      width: 24px;
      height: 3px;
      background: #0b6fff;
    }

    h3 {
      font-size: 30px;
      color: #333333;
      font-weight: 400;
    }
  }
}
.course.mobile {
  display: block;

  .title {
    width: 100%;
    position: unset;
    padding: 0 30px;
    box-sizing: border-box;
    h4 {
      color: #888888;
      font-weight: 400;
      font-size: 14px;
      height: 20px;
      line-height: 20px;
    }

    & > span {
      display: block;
      margin: 20px 0;
      width: 24px;
      height: 3px;
      background: #0b6fff;
    }

    h3 {
      color: #333333;
      font-weight: 400;
      font-size: 20px;
      margin-bottom: 50px;
    }
  }
  .left {
    width: 100%;
    .main {
      display: block;
      padding: 60px 10%;
      box-sizing: border-box;
      & > div {
        width: 100%;
        p {
          font-size: 14px !important;
          text-align: center;
        }
      }
      & > img {
        margin-top: 50px;
        width: 100%;
      }
    }
  }
}

.team {
  max-width: 1400px;
  margin: 100px auto 0;
  .team-top {
    display: flex;
    justify-content: space-between;
    div {
      h4 {
        font-size: 18px;
        color: #888888;
        font-weight: 400;
        height: 26px;
        line-height: 26px;
      }

      & > span {
        display: block;
        margin: 0 0 20px;
        width: 24px;
        height: 3px;
        background: #0b6fff;
      }

      h3 {
        font-size: 30px;
        color: #333333;
        font-weight: 400;
      }
    }
  }
  .team-main {
    margin-top: 50px;
    background-image: url("../assets/team-bg.png");
    background-size: 100% 100%;
    display: flex;
    div {
      width: 20%;
      padding: 120px 0;
      box-sizing: border-box;
      & > span {
        margin: 0 auto;
        display: block;
        width: 16px;
        height: 3px;
        background: #ffffff;
      }
      h5 {
        margin-top: 60px;
        font-size: 60px;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        margin-bottom: 50px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 28px;
      }
      p:nth-last-of-type(1) {
        margin-bottom: 60px;
      }
    }
  }
}
.team.mobile {
  .team-top {
    padding: 0 30px;
    box-sizing: border-box;
    div {
      h4 {
        font-size: 14px;
      }
      h3 {
        font-size: 20px;
      }
      span {
        margin-top: 0;
      }
    }
  }
  .team-main {
    div {
      h5 {
        font-size: 20px;
      }
      p {
        font-size: 12px;
      }
      span {
        display: none;
      }
    }
  }
}

.cooperation {
  max-width: 1400px;
  margin: 115px auto 100px;
  .cooperation-top {
    display: flex;
    justify-content: space-between;
    div {
      h4 {
        font-size: 18px;
        color: #888888;
        font-weight: 400;
        height: 26px;
        line-height: 26px;
      }

      & > span {
        display: block;
        margin: 0 0 20px;
        width: 24px;
        height: 3px;
        background: #0b6fff;
      }

      h3 {
        font-size: 30px;
        color: #333333;
        font-weight: 400;
      }
    }
  }
  .cooperation-main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > div {
      width: 15%;
      margin: 20px 0;
      box-shadow: 0px 0px 10px #ccc;
      padding: 10px;
      box-sizing: border-box;
    }
    & > div:nth-last-of-type(1) {
      opacity: 0;
    }
  }
}
.cooperation.mobile {
  .cooperation-top {
    padding: 0 30px;
    box-sizing: border-box;
    div {
      h4 {
        font-size: 14px;
      }
      h3 {
        font-size: 20px;
      }
    }
  }
  .cooperation-main {
    div {
      img {
        width: 100%;
      }
    }
  }
}
</style>
<style>
.course .left .el-tabs__nav {
  float: right !important;
}
.course.mobile .left .el-tabs__nav {
  width: 100% !important;
}
.course.mobile .left .el-tabs__item {
  float: left !important;
  width: 33.3333% !important;
  text-align: center !important;
}
.course .left .el-tabs__content {
  overflow: visible !important;
}
</style>
