<template>
  <div>
    <!--   头部导航     -->
    <headerC :header_fixed="header_fixed" :nav_active="nav_active"></headerC>
    <!-- 产品头图 -->
    <HeadImg :pcBg="pcBg" :mobileBg="mobileBg"></HeadImg>

    <div class="problem-main" :class="{ mobile: screenWidth <= 1400 }">
      <ul class="top-link" v-if="screenWidth > 1400">
        <li class="active" @click="intoView('problem-main')">软件问题</li>
      </ul>
      <div class="box">
        <div class="left">
          <div>
            <div class="title">
              <img src="../assets/problem-title-icon.png" alt="" />
              <h4>哪些情况不属于九州代驾服务范畴？</h4>
            </div>
            <p>
              军车、无牌照车辆、境外车、外地进京无进京证、肇事逃逸车辆、车内有违禁品、临时牌照、无保险车辆、客户属于醉酒状态并无旁人陪伴、营运车辆、车辆手续不齐、改装车辆、政府职能车辆。
            </p>
          </div>

          <div>
            <div class="title">
              <img src="../assets/problem-title-icon.png" alt="" />
              <h4>九州代驾服务开通城市都哪些？</h4>
            </div>
            <p>
              唐山 正定 威县 栾城 保定 衡水 邢台 秦皇岛 邯郸 菏泽 天津 涿州市
              淄博市 德州市 青岛 赤峰市 红河哈尼族彝族自治州 安平县 青县 定州市
              赵县 新乐市 晋州市 辛集市 元氏县
            </p>
          </div>

          <div>
            <div class="title">
              <img src="../assets/problem-title-icon.png" alt="" />
              <h4>九州代驾手机客户端都支持哪些手机系统？</h4>
            </div>
            <p>
              目前九州代驾手机客户端支持：苹果和安卓操作系统。小程序支持支付宝小程序和微信小程序。
            </p>
          </div>

          <div>
            <div class="title">
              <img src="../assets/problem-title-icon.png" alt="" />
              <h4>九州代驾如何收费？</h4>
            </div>
            <p>因为各城市政策不同，具体收费标准请查看软件首页计费标准</p>
          </div>

          <div>
            <div class="title">
              <img src="../assets/problem-title-icon.png" alt="" />
              <h4>代驾司机在为其代驾过程中出现交通违规该如何处理？</h4>
            </div>
            <p>
              上午10：00-下午18：00点，拨打客服电话，经客服核实确认，所有违规责任由代驾司机承担。
            </p>
          </div>

          <div>
            <div class="title">
              <img src="../assets/problem-title-icon.png" alt="" />
              <h4>代驾师傅多久能到达客户指定地点？</h4>
            </div>
            <p>
              平均到达客户时间为：6分钟（除恶劣天气、较偏远地方），具体情况请与代驾师傅具体沟通。
            </p>
          </div>
        </div>
        <div class="right">
          <div class="mail">
            <div class="left">
              <h4>客服邮箱</h4>
              <p>jzhoudaijia@163.com</p>
            </div>
            <div class="right">
              <img src="../assets/problem-mail-img.png" alt="" />
            </div>
          </div>

          <div class="phone">
            <div class="left">
              <h4>招商热线</h4>
              <p>155-5006-3222</p>
            </div>
            <div class="right">
              <img src="../assets/problem-phone-icon.png" alt="" />
            </div>
          </div>

          <div class="erweima">
            <div class="left">
              <h4>关注公众号</h4>
              <p>了解更多相关资讯</p>
            </div>
            <div class="right">
              <img src="../assets/problem-last-img.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  底部  -->
    <footerC></footerC>
  </div>
</template>

<script>
let _this;
import HeadImg from "@/views/headImg.vue";
import headerC from "@/components/headerC.vue";
import footerC from "@/components/footerC.vue";

export default {
  name: "problem",
  components: {
    HeadImg,
    headerC,
    footerC,
  },
  created() {
    _this = this;
  },
  data() {
    return {
      // pcBg: "@/assets/problem-top-bg.png",
      // mobileBg: "@/assets/problem-top-mobile.png",
      pcBg: require("../assets/problem-top-bg.png"),
      mobileBg: require("../assets/problem-top-mobile.png"),
      // mobileBg: "../assets/problem-top-mobile.png",
      header_fixed: false,
      nav_active: "problem",
      screenWidth: document.body.clientWidth, // 屏幕宽
      scroll: "",
    };
  },
  watch: {
    scroll() {
      if (_this.scroll > 0) {
        _this.header_fixed = true;
      } else {
        _this.header_fixed = false;
      }
    },
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.menu);

    // 监听窗口宽度变化
    window.addEventListener("resize", function () {
      window.screenWidth = document.body.clientWidth;
      _this.screenWidth = window.screenWidth;
    });
  },
  methods: {
    menu() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(this.scroll)
    },
    // 锚记链接方法
    intoView(val) {
      document.getElementById(val).scrollIntoView();
    },
  },
};
</script>

<style lang="scss" scoped>
.problem-top {
  width: 100%;
  height: 450px;
  background-image: url("../assets/problem-top-bg.png");
  background-size: 100% 100%;
  padding-top: 200px;
  padding-left: 5%;
  box-sizing: border-box;

  div {
    h2 {
      font-weight: bold;
      font-style: italic;
      font-size: 52px;
      color: #ffffff;

      span {
        font-size: 36px;
        color: #73acff;
      }
    }

    p {
      font-size: 20px;
      color: #c8deff;
      font-weight: 400;
      margin-top: 10px;
    }
  }
}

.problem-top.mobile {
  background-image: url("../assets/problem-top-mobile.png");
  height: calc(100vw * 0.75);
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 100px 0 0;
  box-sizing: border-box;

  div {
    h2 {
      font-size: 26px;
      text-align: center;

      span {
        display: block;
        font-size: 24px;
      }
    }

    p {
      display: none;
    }
  }
}

.problem-main {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;
  .top-link {
    display: flex;
    border-bottom: 1px solid #e3e5e8;

    li {
      height: 77px;
      line-height: 77px;
      margin: 0 20px;
      cursor: pointer;
    }

    .active {
      border-bottom: 3px solid #0b6fff;
    }
  }
  .box {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
    & > .left {
      width: 74.5%;
      & > div {
        margin: 40px 0;
        .title {
          display: flex;
          align-items: center;
          height: 60px;
          line-height: 60px;
          border-bottom: 1px solid #e3e5e8;
          margin-bottom: 25px;
          img {
            display: block;
            width: 26px;
            height: 26px;
            margin-right: 20px;
          }
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #555555;
        }
      }
    }
    .right {
      width: 23.5%;
      & > div {
        height: 192px;
        display: flex;
        padding: 0 38px;
        justify-content: space-between;
        box-sizing: border-box;
        align-items: center;
        .left {
          h4 {
            font-size: 20px;
            font-weight: 400;
          }
          p {
            font-weight: bold;
            font-size: 16px;
            margin-top: 20px;
          }
        }
        .right {
          width: auto;
        }
      }
      .mail {
        border: 1px solid #e1e6f0;
        background: #ffffff;
        .left {
          h4 {
            color: #333333;
          }
          p {
            color: #888888;
          }
        }
      }
      .phone {
        margin: 20px 0;
        border: 1px solid #e1e6f0;
        background: #ffffff;
        background: #f9faff;
        .left {
          h4 {
            color: #333333;
          }
          p {
            color: #257fff;
          }
        }
      }
      .erweima {
        margin: 20px 0;
        border: 1px solid #e1e6f0;
        background: linear-gradient(90deg, #0b6fff, #388aff);
        .left {
          h4 {
            color: #ffffff;
          }
          p {
            color: #ffffff;
          }
        }
      }
    }
  }
}

.problem-main.mobile {
  .box {
    display: block;
    & > .left,
    & > .right {
      width: 100%;
    }
    & > .left {
      .title {
        height: auto;
      }
    }
  }
}
</style>
