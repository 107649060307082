<template>
  <div class="join">
    <!--   头部导航     -->
    <headerC :header_fixed="header_fixed" :nav_active="nav_active"></headerC>
    <!-- 产品头图 -->
    <HeadImg :pcBg="pcBg" :mobileBg="mobileBg"></HeadImg>

    <!--  源码系统介绍图  -->
    <codeImg :home="home" style="margin-bottom: 100px"></codeImg>

    <!--  底部  -->
    <footerC></footerC>
  </div>
</template>

<script>
let _this;
import HeadImg from "@/views/headImg.vue";

import headerC from "@/components/headerC.vue";
import codeImg from "@/views/codeImg.vue";
import footerC from "@/components/footerC.vue";

export default {
  name: "join",
  components: {
    HeadImg,
    headerC,
    codeImg,
    footerC,
  },
  created() {
    _this = this;
  },
  data() {
    return {
      pcBg: require("../assets/code-pc.png"),
      mobileBg: require("../assets/code-phone.png"),
      home: "",
      header_fixed: false,
      nav_active: "goodsCode",
      screenWidth: document.body.clientWidth, // 屏幕宽
      scroll: "",
    };
  },
  watch: {
    scroll() {
      if (_this.scroll > 0) {
        _this.header_fixed = true;
      } else {
        _this.header_fixed = false;
      }
    },
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.menu);

    // 监听窗口宽度变化
    window.addEventListener("resize", function () {
      window.screenWidth = document.body.clientWidth;
      _this.screenWidth = window.screenWidth;
    });
  },
  methods: {
    menu() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(this.scroll)
    },
    // 锚记链接方法
    intoView(val) {
      document.getElementById(val).scrollIntoView();
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-introduce-ul {
  display: flex;
  flex-wrap: wrap;
}

/* 响应式布局 */
@media screen and (min-width: 1px) and (max-width: 768px) {
  .goods-introduce {
    width: 90%;
    margin: 0 auto;

    /* 设置精品文字图片外边距 */
    .introduce-img {
      margin-top: 10px;
      margin-bottom: 10px;

      /* 设置精品文字图片大小 */
      .title-img {
        width: 200px;
        height: auto;
      }
    }

    .goods-introduce-ul {
      margin-bottom: 20px;
      justify-content: space-between;

      li {
        flex-basis: 46%;
        padding-left: 10px;
        width: 170px;

        img.imgPC {
          width: 96%;
          height: auto;
        }

        h3 {
          /* text-align: center; */
          /* 根据浏览器宽度，文字大小动态改变 */
          font-size: 3vw;
          font-weight: 400;
          color: #121419;
          margin-bottom: 6px;
          margin-top: 6px;
        }

        p {
          /* text-align: center; */
          /* 根据浏览器宽度，文字大小动态改变 */
          font-size: 3vw;
          color: #ff6f00;

          span:nth-child(2) {
            text-decoration: line-through;
            /* 根据浏览器宽度，文字大小动态改变 */
            font-size: 2.4vw;
            color: #b9b9b9;
            margin-left: 4px;
          }
        }

        /* 第一排图片距离下面图片的间距 */
        &:nth-child(-n + 2) {
          margin-bottom: 10px;
        }

        /* 设置4的倍数产品图片的外边距 */
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .goods-introduce {
    width: 90%;
    margin: 0 auto;

    /* 设置精品文字图片外边距 */
    .introduce-img {
      margin-top: 10px;
      margin-bottom: 10px;

      /* 设置精品文字图片大小 */
      .title-img {
        width: 300px;
        height: auto;
      }
    }

    .goods-introduce-ul {
      margin-bottom: 20px;
      justify-content: space-between;

      li {
        flex-basis: 30%;
        padding-left: 10px;
        width: 170px;

        img.imgPC {
          width: 96%;
          height: auto;
        }

        h3 {
          /* text-align: center; */
          /* 根据浏览器宽度，文字大小动态改变，，文字大小动态改变 大小是p的30% */
          font-size: 2.4vw;
          font-weight: 400;
          color: #121419;
          margin-bottom: 6px;
          margin-top: 6px;
        }

        p {
          /* text-align: center; */
          /* 根据浏览器宽度，文字大小动态改变 */
          font-size: 3vw;
          color: #ff6f00;

          span:nth-child(2) {
            text-decoration: line-through;
            /* 根据浏览器宽度，文字大小动态改变，，文字大小动态改变 大小是p的30% */
            font-size: 2.4vw;
            color: #b9b9b9;
            margin-left: 4px;
          }
        }

        /* 第一排图片距离下面图片的间距 */
        &:nth-child(-n + 2) {
          margin-bottom: 10px;
        }

        /* 设置4的倍数产品图片的外边距 */
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .goods-introduce {
    width: 90%;
    margin: 0 auto;

    /* 设置精品文字图片外边距 */
    .introduce-img {
      margin-top: 10px;
      margin-bottom: 10px;

      /* 设置精品文字图片大小 */
      .title-img {
        width: 300px;
        height: auto;
      }
    }

    .goods-introduce-ul {
      margin-bottom: 20px;
      justify-content: space-between;

      li {
        flex-basis: 30%;
        padding-left: 10px;
        width: 170px;

        img.imgPC {
          width: 96%;
          height: auto;
        }

        h3 {
          /* text-align: center; */
          /* 根据浏览器宽度，文字大小动态改变 大小是p的30% */
          font-size: 1.9vw;
          font-weight: 400;
          color: #121419;
          margin-bottom: 6px;
          margin-top: 6px;
        }

        p {
          /* text-align: center; */
          /* 根据浏览器宽度，文字大小动态改变 */
          font-size: 2.4vw;
          color: #ff6f00;

          span:nth-child(2) {
            text-decoration: line-through;
            /* 根据浏览器宽度，文字大小动态改变，文字大小动态改变 大小是p的30% */
            font-size: 1.9vw;
            color: #b9b9b9;
            margin-left: 4px;
          }
        }

        /* 第一排图片距离下面图片的间距 */
        &:nth-child(-n + 2) {
          margin-bottom: 10px;
        }

        /* 设置2的倍数产品图片的外边距 */
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .goods-introduce {
    /* 设置产品图片父元素宽度 */
    width: 1400px;
    margin: 0 auto;

    .introduce-img {
      /* 设置精品文字图片外边距 */
      margin-top: 71px;
      margin-bottom: 31px;

      /* 设置精品文字图片大小 */
      .title-img {
        width: 337px;
        height: auto;
      }
    }

    .goods-introduce-ul {
      margin-bottom: 204px;

      li {
        margin-right: 15px;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid #2b80ff;
        }

        width: 345px;

        h3 {
          /* text-align: center; */
          font-size: 24px;
          font-weight: 400;
          color: #121419;
          margin-bottom: 26px;
          margin-top: 19px;
        }

        p {
          /* text-align: center; */
          font-size: 30px;
          color: #ff6f00;

          span:nth-child(2) {
            text-decoration: line-through;
            font-size: 24px;
            color: #b9b9b9;
            margin-left: 4px;
          }
        }

        /* 第一排图片距离下面图片的间距 */
        &:nth-child(-n + 4) {
          margin-bottom: 43px;
        }

        /* 设置4的倍数产品图片的外边距 */
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
